.s-player {
    display: flex;
    flex-direction: column;
    gap: 20px;

    width: 100%;
    height: 100%;


    .title {
        width: 100%;
        border: 1px solid grey;
        border-radius: 10px;
        padding: 10px 20px;

        // box-shadow: 0px 0px 41px 13px rgba(214,214,214,0.61);
        // -webkit-box-shadow: 0px 0px 41px 13px rgba(214,214,214,0.61);
        // -moz-box-shadow: 0px 0px 41px 13px rgba(214,214,214,0.61);

        h1 {
            font-size: 22px;
            font-weight: 500;
        }
    }


    .payerSubContainer {
        height: calc(100vh - 219px);;
        overflow: auto;
        
        .video {
            display: grid;
            place-items: center;
            width: 100%;
            padding: 20px;
    
            border: 1px solid grey;
            border-radius: 10px;
    
            box-shadow: 0px 0px 13px -1px rgba(0,0,0,0.19) inset;
            -webkit-box-shadow: 0px 0px 13px -1px rgba(0,0,0,0.19) inset;
            -moz-box-shadow: 0px 0px 13px -1px rgba(0,0,0,0.19) inset;
            
            video {
                border-radius: 10px;
                width: 100%;
                max-height: calc(100vh - 330px); 
            }
        }
    }
}