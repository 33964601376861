$primary-color: #000000;
$secondary-color: #3b3b3b;



* {
  margin: 0;padding: 0;
  box-sizing: border-box;
  line-height: 1;
  // font-family: 'Montserrat', sans-serif;
  font-family: 'Archivo', sans-serif;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0;
}

body {
  font-size: 62.5%;
  background-color: #fff;
}

a {
  text-decoration: none;
}

.h1 {
  font-size: clamp(1.8rem, 3.6vw, 2.4rem);
  font-weight: 400;
  color: #333;
  line-height: 3rem;
}

.h2 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #333;
  line-height: 2rem;
}

.h3 {
  font-size: clamp(20px, 2vw, 24px);
  font-weight: 500;
  
  color: #333;
  line-height: clamp(1.7rem, 3vw, 2rem);
}

.h4 {
  font-size: clamp(14px, 2vw, 18px);
  font-weight: 500;
  
  color: #ccc;
  line-height: clamp(1.1rem, 3vw, 1.5rem);
}



// Swal modal
.swal2-html-container {
  overflow: unset !important;
}

.afterPost {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 18px;

  .btn {
    background-color: #fff!important;
    outline: none;
    border: 3px solid #0464ea!important;
    border-radius: 25px!important;
    color: #000!important;
    font-size: 16px!important;
    font-weight: 500;
    letter-spacing: .3px;
    margin-top: 0;
    padding: 12px 24px;
    cursor: pointer;
    text-decoration: none;
    transition: all .2s;

    &:hover {
      transition: all .2s;
      transform: scale(1.05);
    }

    &:nth-child(2) {
      border-color: #39C641 !important;
    }
  }
}





//Scrollbar
/* Estilos para la barra de desplazamiento en navegadores WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px; /* Ancho de la barra de desplazamiento */
  height: 9px;
}

::-webkit-scrollbar-track {
  background: hsl(0, 0%, 91%); /* Color de fondo de la pista de la barra de desplazamiento */
}

::-webkit-scrollbar-thumb {
  background: #aaaaaa; /* Color del mango de la barra de desplazamiento */
  border-radius: 4px; /* Radio de borde del mango de la barra de desplazamiento */
}

::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3; /* Color del mango de la barra de desplazamiento al pasar el mouse */
}


input[type='number'] {
  /* Oculta las flechas en navegadores webkit */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Elimina el margen predeterminado */
  }
  
  /* Oculta las flechas en Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}