.LessonCard {
  display: flex;
  flex-direction: column;
  gap: 5px;

  // width: 200px;
  flex-shrink: 0;

  justify-content: center;
  // align-items: center;
  
  border: 1px solid grey;
  border-radius: 10px;
  padding: 10px 30px;

  font-size: 14px;
  
  &:hover {
    box-shadow: 0px 0px 13px -1px rgba(0,0,0,0.09) inset;
    -webkit-box-shadow: 0px 0px 13px -1px rgba(0,0,0,0.09) inset;
    -moz-box-shadow: 0px 0px 13px -1px rgba(0,0,0,0.09) inset;
    cursor: pointer;
  }
  
  &.disabled {
    opacity: .1;
    pointer-events: none;
  }
}