.s-lessons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow-x: scroll;
    
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    
    border-radius: 10px;
    border: 1px solid grey;
    text-wrap: nowrap;

    .isotipo {
        height: 100%;
        filter: invert(1) drop-shadow(0 0 0);
    }
}