.Certificate {
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 50px;

    display: flex;
    justify-content: center;

    .certificate-layout {
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 100%; // Make the width responsive
        height: 100%;
        margin: 0 auto; // Center the certificate
        padding: 50px;

        border: 1px solid #ccc;
        border-radius: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        text-align: center;
        font-family: Arial, sans-serif;
        background: white; // Ensure the background is white

        overflow: hidden;

        .header {
            z-index: 20;
            
            position: absolute;
            top: 100px;
            left: 50px;
            right: 50px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 10px;

            .gnp-logo {
                height: 50px; // Adjust the size as needed
            }

            .mid-logo {
                height: 80px; // Adjust the size as needed
                // filter: drop-shadow(0 0 0);
            }
        }

        .content {
            z-index: 10;

            padding: 20px;

            color: #002d62;

            p {
                margin: 10px 0;
                font-weight: lighter;
                font-size: 20px;
            }

            h1 {
                font-size: 50px;
                font-weight: normal;
                text-transform: uppercase;
                
                margin: 20px 0;
                color: #002d62; // Adjust color as needed
            }

            h2 {
                font-size: 30px;
                font-weight: lighter;
                margin: 10px 0;
                color: #002d62; // Adjust color as needed
            }

            h3 {
                margin: 20px 0;
                font-size: 30px;
                font-weight: normal;
                color: #ff6600; // Adjust color as needed
            }
        }

        .header-decoration {
            z-index: 1;
            position: absolute;
            top: 0; left: 0; right: 0;
            
            width: 100%;
            height: 15vh;
            
            img {
                display: block;

                width: 100%;
                height: 100%;
                
                object-fit: cover;
                object-position: bottom;
            }
        }
        
        .footer-decoration {
            z-index: 1;
            position: absolute;
            bottom: 0; left: 0; right: 0;
            
            width: 100%;
            height: 15vh;
            margin-top: 20px;

            img {
                display: block;

                width: 100%;
                height: 100%;

                object-fit: cover;
                object-position: bottom;
                transform: rotate(180deg);
            }
        }
    }
}



.repasar {
    position: absolute;
    bottom: 10px;
    
    width: auto;
    padding: 10px;

    border: none;
    border-radius: 10px;
    background-color: black;

    color: white;
    font-size: 14px;
    text-align: left;

    cursor: pointer;
    transition: .1s all;

    &:hover {
        transition: .1s all;
        transform: scale(1.05);
    }
}