@import "./Global.scss";

.appLayout {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  min-height: 100vh;
  width: 100%;

  background-color: #1d1d1d;


  // we dont use the overlay on this project
  .bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    background-image: url("../Images/Background.jpg");
    background-position: center;
    background-size: cover;
    z-index: 1;
    opacity: .8;
  }

  >*:not(.bg-overlay) {
    z-index: 10;
  }

  .Header {
    z-index: 10;

    display: grid;
    place-items: center;

    height: 100px;
    width: 100px;

    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 0 0 25px 0;

    cursor: pointer;

    &:hover {
      transition: all .2s;
      background-color: $secondary-color;
    }

    img {
      height: 50px;
      filter: invert(1) drop-shadow(0 0 0);
    }
  }

  .App {
    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;

    .mobile-actions {
      display: none;
    }

    @media screen and (max-width: 1000px) {
      flex-direction: column;
      min-height: calc(100vh - 80px);

      .app-page-container,
      .app-page-wrap-container {
        min-height: unset;
      }

      .app-page-btn {
        display: none;
      }

      .mobile-actions {
        display: flex;

        .app-page-btn {
          display: grid;
        }
      }
    }




    //Containers
    .ilustration {
      border-radius: 15px 95px 15px 15px;
      width: min(100%, 350px);
      max-height: 298px;
      object-fit: cover;

      @media screen and (max-width: 700px) {
        border-radius: 25px !important;
      }
    }

    .app-page-wrap-container {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-bottom: 20px;
      width: min(515px, 85%);
      min-height: calc(100vh - 80px);

      @media screen and (max-width: 1000px) {
        margin-top: 50px;
        margin-bottom: 0;
      }
    }

    .app-page-container {
      display: flex;
      flex-direction: column;
      padding: 50px;
      background-color: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
      border-radius: 25px;

      .appPageLogo {
        margin: 0 auto;
        height: 80px;
        margin: 20px 0;

        @media screen and (max-width: 1000px) {
          height: unset;
          width: min(100%, 300px);
        }
      }
    }

    .app-page-btn {
      display: grid;
      place-items: center;

      margin: 24px;
      width: 56px;
      height: 56px;

      border-radius: 50%;
      background-color: #ffffff;

      .sbi {
        font-size: 14px;
        font-weight: 700;
        color: #000000;
      }
    }




  }
}



// Botones que hago con iconos de primeicons dentro
.PiButton {
  margin: 25px 0 50px;
  padding: 20px;
  border-radius: 50%;
  border: none;
  outline: none;

  background-color: $primary-color;
  color: rgb(0, 0, 0);

  cursor: pointer;
  transition: all .3s;

  &:hover {
    transition: all .3s;
    transform: scale(1.1);
  }

  &.PiButtonDisabled {
    opacity: .2;
    cursor: unset;
  }
}



















.step-btn {
  display: grid;
  place-items: center;

  margin: 24px;
  width: 56px;
  height: 56px;

  border-radius: 50%;
  background-color: #ffffff;

  .sbi {
    font-size: 14px;
    font-weight: 700;
    color: rgb(0, 0, 0);
  }
}