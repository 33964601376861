.filesContainer {

    .h3 {
        margin: 20px;
        cursor: pointer;
    }

    .files {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 20px;

        .fileContainer {
            display: flex;
            flex-direction: row;
            gap: 20px;
            align-items: center;
            justify-content: start;

            padding: 20px;            
            min-width: 200px;
            height: 40px;

            border-radius: 15px;
            border: 1px solid grey;

            font-size: 14px;
            color: black;
            transition: all .2s;
            
            &:hover {
                transition: all .2s;
                box-shadow: 0px 0px 13px -1px rgba(0,0,0,0.19) inset;
                -webkit-box-shadow: 0px 0px 13px -1px rgba(0,0,0,0.19) inset;
                -moz-box-shadow: 0px 0px 13px -1px rgba(0,0,0,0.19) inset;
            }
        }
    }
}