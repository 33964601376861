.Question {
    padding: 10px;
    border: 1px solid grey;
    border-radius: 10px;

    margin-bottom: 20px;

    

    h3 {
        margin-bottom: 5px;
        
        font-size: 16px;
        font-weight: normal;
        line-height: 1.2;
    }

    .optionsContainer {
        display: flex;
        flex-direction: column;
        gap: 5px;
        

        .optionWrap {
            display: flex;
            align-items: center;
            gap: 5px;

            border-top: 1px solid grey;
            padding-top: 5px;
    
            .red {
                color: rgb(231, 11, 11) !important;
            }
        
            .green {
                color: rgb(9, 209, 9) !important;
            }

            .p-radiobutton.p-highlight .p-radiobutton-box {
                border-color: #000000;
                background: #000000;
            }

            label {
                font-size: 13px;
            }
        }
    }
    
    
}