.SchoolLayout {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 30px;
  line-height: 1;
  background-color: rgb(251, 251, 251);
  color: black;

  // aqui tiene que ir la disposición de el layout con grid
  .s-layout {
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-template-rows: 1fr 80px;
    gap: 15px;

    width: 100%;
    height: 100%;
    
    .s-player { grid-area: 1 / 1 / 2 / 2; }
    .s-quiz { grid-area: 1 / 2 / 2 / 3; }
    .s-lessons { grid-area: 2 / 1 / 3 / 3; }
  }
}