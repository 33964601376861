.Dashboard {
    width: 100%;
    height: 100vh;

    padding: 50px;

    .DashboardWrapper {
        display: grid;
        grid-template-columns: 1fr 300px;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        gap: 40px;

        height: 100%;
        width: 100%;


        .reclutas {
            grid-area: 1 / 1 / 2 / 2;

            padding: 20px;
            height: 100%;
            width: 100%;

            border: 1px solid grey;
            border-radius: 10px;

            overflow: auto;

            .reclutasWrap {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                height: 100%;
                width: 100%;


                h1 {
                    margin-bottom: 30px;

                    font-size: 25px;
                    font-weight: normal;
                }

                .ReclutaProgress {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    margin-bottom: 20px;
                    padding: 10px;

                    border: 1px solid grey;
                    border-radius: 10px;

                    width: 100%;
                    

                    p {
                        font-size: 10px;
                        
                        span {
                            font-size: 20px;
                        }
                    }

                    .p-progressbar {
                        .p-progressbar-value {
                            background-color: black;
                            border-radius: 10px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .nuevoRecluta {
            grid-area: 1 / 2 / 2 / 3;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            padding: 50px 30px;
            height: 100%;
            width: 100%;

            border: 1px solid grey;
            border-radius: 10px;

            h1 {
                margin-bottom: 30px;

                font-size: 25px;
                font-weight: normal;
            }

            h3 {
                font-size: 20px;
                font-weight: normal;
            }

            input {
                font-size: 20px;
            }

            button {
                width: 100%;
                padding: 12px;
        
                border: none;
                border-radius: 10px;
                background-color: black;
        
                color: white;
                font-size: 18px;
                text-align: left;
        
                cursor: pointer;
                transition: .1s all;
        
                &:hover {
                    transition: .1s all;
                    transform: scale(1.05);
                }

                &:disabled {
                    opacity: .1;
                    pointer-events: none;
                }
            }
        }
    }

}