.s-quiz {
    width: 100%;
    height: 100%;
    
    border: 1px solid grey; 
    border-radius: 10px;
    padding: 40px 30px;

    overflow: auto;
    
    h2 {
        font-size: 20px;
        font-weight: 500;
        font-weight: normal;
        padding: 10px;
        border: 1px solid grey;
        border-radius: 10px;
    }

    .separador {
        width: 97%;
        height: 1px;

        margin: 15px auto;

        border-bottom: 1px solid grey;
    }

    button {
        width: 100%;
        padding: 12px;

        border: none;
        border-radius: 10px;
        background-color: black;

        color: white;
        font-size: 18px;
        text-align: left;

        cursor: pointer;
        transition: .1s all;

        &:hover {
            transition: .1s all;
            transform: scale(1.05);
        }
    }
}